import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import routes from 'routes/routes';

import { isDexcomEnabled } from 'common/validators';
import { EditWithingsOrderPost, MarkAsRead, Subscribe } from 'features/programs';
import { Avatar, Button, Col, Image, RichTextRenderer, Row, Title } from 'ui';
import EditPoll from '../../../features/polls/EditPoll';
import PollRendererCore from '../../../features/polls/PollRendererCore';
import EditPost from '../../../features/posts/EditPost';
import ActivityTask from '../../../features/programs/feed/ActivityTask';
import EditActivity from '../../../features/programs/feed/EditActivity';
import { can } from '../../utils/permissions';
import { ChatToButton } from '../../widgets/chat-to-button';
import { Box } from '../box';
import { Attachments } from '../feed-object/shared/Attachments';
import { Icon } from '../icon';
import { RedText, SmallContent, Text } from '../text';
import { ThemeConsumer } from '../theme';
import { Translation } from '../translation';
import { ItemActionsRenderer } from './shared/ActionsRenderer';
import { CalendarIcon } from './shared/CalendarIcon';
import { Footer } from './shared/Footer';
import { UpdateTimelineObject } from './UpdateTimelineObject';

import { ReactComponent as ActivityIcon } from 'assets/course/activity.svg';
import { ReactComponent as EventIcon } from 'assets/course/event.svg';
import { ReactComponent as PollIcon } from 'assets/course/poll.svg';
import { ReactComponent as PostIcon } from 'assets/course/post.svg';
import { ReactComponent as WithingsOrderPostIcon } from 'assets/course/withings_order_post.svg';

import variables from 'common/styles/variables.json';
import { images } from 'common/utils';

const TEXT_PREVIEW_LENGTH = 100;

export class Renderer extends React.Component {
  state = {
    draft: undefined,
    isOpenEditorModal: false,
    editActivity: false,
    editPoll: false,
  };

  onRequestClose = () => {
    this.setState({
      draft: undefined,
      isOpenEditorModal: false,
      editActivity: false,
      editPoll: false,
    });
  };

  onSubmit = () => {
    this.onRequestClose();
    this.props.onEditObject();
  };

  initiateEditing = async (object) => {
    if (object.type === 'post') {
      this.setState({ draft: object, editPost: true });
    } else if (object.type === 'withings_order_post') {
      this.setState({ draft: object, editOrderPost: true });
    } else if (object.type === 'activity') {
      this.setState({ draft: { ...object.activity, programs_topic: object.programs_topic }, editActivity: true });
    } else if (object.type === 'poll') {
      this.setState({
        draft: { ...object.poll, programs_topic: object.programs_topic, attachments: object.attachments },
        editPoll: true,
      });
    } else if (object.type === 'event') {
      const response = await this.props.getEventDetail(object.event.id);
      this.setState({
        draft: {
          ...response.data,
          type: object.type,
          program_topic_id: object.programs_topic?.id,
          publish_at: object.publish_at,
        },
        isOpenEditorModal: true,
      });
    }
  };

  renderTextPreview = (item) => {
    let title;
    let { type } = item;

    switch (item.type) {
      case 'post':
        title = item.post?.title ?? item.post?.text_content;
        break;
      case 'withings_order_post':
        title = item.withings_order_post?.title ?? item.withings_order_post?.text_content;
        type = <Trans>Withings order</Trans>;
        break;
      case 'activity':
        title = item.activity?.name;
        break;
      case 'event':
        title = item.event?.name;
        break;
      case 'poll':
        title = item.poll?.title ?? item.poll?.description;
        break;
      default:
        title = item.text_content;
    }

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="topic-item__text-preview" onClick={this.props.onOpenModal}>
            <Text className="topic-item__text-preview__short" weight="400" size={14} component="p">
              <Text style={{ color: theme.color.brand }}>
                {title && !item.event && title.length > TEXT_PREVIEW_LENGTH ? (
                  <>{title.substr(0, TEXT_PREVIEW_LENGTH)}...</>
                ) : (
                  <Text style={{ color: theme.color.brand }} id="item_title">
                    {title}
                  </Text>
                )}
              </Text>
            </Text>
            <Text className="topic-item__text-preview__date" size="12px" component="p">
              {this.props.t('dateFormatShortMonthDayYearTime', { date: new Date(item.publish_at) })} • {type}
            </Text>
            <Text size="12px" weight="400" component="p" style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                size="small"
                src={item.author.profile_photo?.url ?? images.default.programCoverPhoto}
                style={{ marginRight: 11 }}
              >
                {item.author.first_name.substring(0, 2).toUpperCase()}
              </Avatar>{' '}
              <Trans>Posted by</Trans> {`${item.author.first_name} ${item.author.last_name}`}
              <ChatToButton legacyUserId={item.author.id} />
            </Text>
          </div>
        )}
      </ThemeConsumer>
    );
  };

  openWithingsOrder = () => this.setState({ isSubscribeOpened: true });

  openDexcomSync = async () => {
    this.props.history.push({
      pathname: `/profile/dexcom-account-sync`,
      state: { backUrl: window.location.pathname, automaticSync: true },
    });
  };

  renderWithingsOrder = (item) => {
    if (item.type !== 'withings_order_post') {
      return null;
    }

    if (item.filled_withings_order) {
      return (
        <Text className="topic-item__order-completed">
          <Trans>Order was successful!</Trans>
        </Text>
      );
    }

    return (
      <Row item={{ align: 'middle', justify: 'center' }}>
        <Button type="primary" className="topic-item__order" onClick={this.openWithingsOrder} size="large">
          Create order
        </Button>
      </Row>
    );
  };

  renderDexcomSync = () => {
    return (
      <div className="modules-filter__synchronized-wrapper">
        <Row item={{ gutter: [0, variables.spaceMd.value] }}>
          <Col item={{ span: 24 }}>
            <Image preview={false} src="/assets/dexcom.png" />
          </Col>
          <Col item={{ span: 24 }}>
            <Title level={2}>How to set up your device and account</Title>
          </Col>
          <Col item={{ span: 24 }}>
            <Text>
              1. Watch the video tutorial below on how to create your Dexcom account and pair the device to this
              account.
            </Text>
          </Col>
          <Col item={{ span: 24 }}>
            <RichTextRenderer
              data={
                '<figure class="media"><oembed url="https://www.youtube.com/watch?v=IMq-M5sdpJ8"></oembed></figure>'
              }
              id="item_content"
            />
          </Col>
          <Col item={{ span: 24 }}>
            <Text>
              2. After your account is created and your device is paired, click the "Synchronize account" button
              below.Follow the prompts to log in to your Dexcom account and sign the HIPAA authorization.
            </Text>
          </Col>
        </Row>

        <Button type="primary" className="topic-item__order" onClick={this.openDexcomSync} size="large">
          Synchronize account
        </Button>
      </div>
    );
  };

  renderLongTextPreviewContent = (item) => {
    const html_content =
      item.post?.html_content ||
      item.event?.html_content ||
      item.withings_order_post?.html_content ||
      item.dexcom_sync?.html_content;
    const objectId = item.post?.id || item.event?.id || item.withings_order_post?.id;

    let title;

    switch (item.type) {
      case 'post':
        title = item.post?.title ?? item.post?.text_content;
        break;
      case 'withings_order_post':
        title = item.withings_order_post?.title ?? item.withings_order_post?.text_content;
        break;
    }

    return (
      html_content && (
        <React.Fragment>
          <Box className="topic-item__long-text">
            {title ? (
              <Title level={5} marginBottom={false}>
                {title}
              </Title>
            ) : null}
            <div
              className={`topic-item__long-text-wrapper ${
                !['post', 'withings_order_post'].includes(item.type) ? 'topic-item__long-text-wrapper--not-post' : ''
              }`}
            >
              <RichTextRenderer data={html_content} id="item_content" />
            </div>
            {this.renderWithingsOrder(item)}
            <Translation id={objectId} type={item.type} />
          </Box>
        </React.Fragment>
      )
    );
  };

  renderEventContent = (item) => {
    return (
      <React.Fragment>
        {item.event.cover_photo ? (
          <div className="topic-item__event-header-image">
            <img src={item.event.cover_photo?.url ?? images.default.eventCoverPhoto} alt="event cover" />
          </div>
        ) : null}
        <Box flexDirection="row" style={{ paddingLeft: 59, paddingRight: 24 }}>
          <CalendarIcon time={item.event.start_time} />
          <Box flexDirection="column" style={{ paddingLeft: 25 }}>
            <div style={{ color: '#A2ABBB', fontSize: 14, paddingBottom: 7 }}>
              {this.props.t('dateFormatShortMonthDayYear', { date: new Date(item.event.start_time) })} •
              <RedText color="#EB3E63" style={{ paddingLeft: 3 }}>
                {this.props.t('dateFormatTime', { date: new Date(item.event.start_time) })}
              </RedText>{' '}
              – {this.props.t('dateFormatShortMonthDayYear', { date: new Date(item.event.end_time) })} •
              <RedText color="#EB3E63" style={{ paddingLeft: 3 }}>
                {this.props.t('dateFormatTime', { date: new Date(item.event.end_time) })}
              </RedText>
            </div>
            <SmallContent style={{ paddingBottom: 12 }}>
              <Trans>Hosted by</Trans> {this.props.course.author_name}
            </SmallContent>
            <SmallContent>{item.event.view_permission}</SmallContent>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  getPostIcon() {
    switch (this.props.item.type) {
      case 'activity':
        return <ActivityIcon />;
      case 'event':
        return <EventIcon />;
      case 'poll':
        return <PollIcon />;
      case 'withings_order_post':
        return <WithingsOrderPostIcon />;
      case 'post':
      default:
        return <PostIcon />;
    }
  }

  onOpenModal = (type, object_id) => {
    if (type === 'post') {
      this.setState({ isPostModalOpen: true });
    } else {
      this.setState({ isEventModalOpen: true });
    }
    this.setState({ currentOpenedObjectId: object_id });
  };

  renderUpdateModal() {
    const { onRequestClose, onSubmit } = this;
    const { actions, viewer, course } = this.props;
    const { isOpenEditorModal, draft } = this.state;

    return (
      <UpdateTimelineObject
        course={course}
        actions={actions}
        viewer={viewer}
        isOpenEditorModal={isOpenEditorModal}
        draft={draft}
        onRequestClose={onRequestClose}
        onSubmit={onSubmit}
      />
    );
  }

  renderActivity = (item) => {
    const dexcomSynced = this.props.viewer.dexcom.synced;

    const shouldRenderDexcomSync =
      isDexcomEnabled() && item.activity.is_dexcom && !dexcomSynced && !can(item.program, 'Course', 'contentCreator');

    if (shouldRenderDexcomSync) {
      return <div>{this.renderDexcomSync(item)}</div>;
    }

    return (
      <div className="topic-item__activity">
        <div className="topic-item__activity-tasks">
          <ActivityTask
            activity={item.activity}
            onSuccess={() => this.props.onEditObject()}
            canDownloadReport={can(this.props.course, 'Course', 'downloadReport')}
            canSubmitData={can(item.activity, 'Activity', 'submitData')}
            isProgramPublished={this.props.course.status === 'published'}
            userSubscribed={can(item.program, 'Course', 'joinedAsUser')}
          />
        </div>
      </div>
    );
  };

  handleSubscribeModalClose = () => {
    this.setState({ isSubscribeOpened: false });
  };

  render() {
    const { item, course, actions, viewer } = this.props;

    return (
      <>
        {this.renderUpdateModal()}
        {this.state.editActivity && (
          <EditActivity
            program={course}
            activity={this.state.draft}
            isOpened={this.state.editActivity}
            onSuccess={() => {
              this.setState({ editActivity: false });
              this.props.onEditObject();
            }}
            onCancel={() => this.setState({ editActivity: false })}
          />
        )}
        {this.state.editPoll && (
          <EditPoll
            program={this.props.course}
            poll={this.state.draft}
            isOpened={this.state.editPoll}
            onSuccess={() => {
              this.setState({ editPoll: false });
              this.props.onEditObject();
            }}
            onCancel={() => this.setState({ editPoll: false })}
          />
        )}
        {this.state.editPost && (
          <EditPost
            program={course}
            objectData={this.state.draft}
            isOpened={this.state.editPost}
            onSuccess={() => {
              this.setState({ editPost: false });
              this.props.onEditObject();
            }}
            onCancel={() => this.setState({ editPost: false })}
          />
        )}
        {this.state.editOrderPost && (
          <EditWithingsOrderPost
            program={course}
            objectData={this.state.draft}
            isOpened={this.state.editOrderPost}
            onSuccess={() => {
              this.setState({ editOrderPost: false });
              this.props.onEditObject();
            }}
            onCancel={() => this.setState({ editOrderPost: false })}
          />
        )}

        <Subscribe
          user={viewer}
          isOpened={this.state.isSubscribeOpened}
          programId={course.id}
          onCancel={() => this.handleSubscribeModalClose()}
          onSuccess={() => {
            this.handleSubscribeModalClose();
            this.props.onEditObject();
          }}
        />

        <div className="topic-item" data-test-id={this.props?.['data-test-id']}>
          <div className="topic-item__header">
            <div className="topic-item__header-icon" onClick={this.props.onOpenModal}>
              {this.getPostIcon()}
            </div>

            {this.renderTextPreview(item)}

            {!item.noReplies ? (
              <div>
                <ItemActionsRenderer
                  onDeleteObject={actions.deleteCurriculumObject}
                  onEditObject={() => this.initiateEditing(item)}
                  onReportObject={actions.reportObject}
                  item={item}
                  viewer={viewer}
                  course={course}
                />
              </div>
            ) : null}

            {!item.noReplies ? (
              <div className="object-header__bookmark">
                <Icon
                  name={`pba-bookmark${item.bookmarked ? '-filled' : '-empty'}`}
                  size={25}
                  color="#717E94"
                  onClick={() => actions.bookmarkObject(item)}
                />
              </div>
            ) : null}
          </div>
          {item.type === 'event' ? this.renderEventContent(item) : null}
          {this.renderLongTextPreviewContent(item)}
          {item.type === 'activity' ? this.renderActivity(item) : null}
          {item.type === 'post' && !can(item.program, 'Course', 'contentCreator') ? (
            <div className="post-mark-as-read">
              <MarkAsRead postId={item.post.id} status={item.post.status} view="other" />
            </div>
          ) : null}
          {item.type === 'poll' ? (
            <div className="topic-item__poll">
              <PollRendererCore
                poll={{ ...item, ...item.poll }}
                isDisabled={can(course, 'Course', 'editProgramContent')}
                showChoicePercentage={can(course, 'Course', 'editProgramContent')}
              />
            </div>
          ) : (
            <Attachments item={item} style={{ padding: '0 24px' }} />
          )}
          {item.type === 'event' ? (
            <div className="topic-item__event-button-wrapper">
              <Button
                className="topic-item__event-button"
                type="primary"
                size="large"
                onClick={() => {
                  this.props.history.push(routes.contentObjects.events(item.event.id));
                }}
              >
                Go to event
              </Button>
            </div>
          ) : null}
          <Footer
            item={item}
            onOpenDetail={() => this.onOpenModal('post', item.id)}
            actions={actions}
            padding={true}
            viewer={this.props.viewer}
            isProgramPublished={course.status === 'published'}
            program={course}
          />
        </div>
      </>
    );
  }
}

export const CourseTopicItem = withTranslation()(withRouter(Renderer));
