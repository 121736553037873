/* global JSBridge */
import * as React from 'react';

import { http } from '../../services/shared';
import { RichTextEditor } from 'ui';
import { uploadImage } from 'common/queries';

export class MobilePageRichTextEditor extends React.Component {
  state = {
    editorData: {
      html: '',
      text: '',
    },
    uploading: false,
  };
  mentions = {};

  getParameterByName = (name) => {
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(window.location.href);
    if (!results) return undefined;
    if (!results[2]) return undefined;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  componentDidMount() {
    http.setHeader('x-app-platform', this.getParameterByName('platform') ?? 'fe-rte');

    const whiteLabel = this.getParameterByName('config');
    if (whiteLabel) {
      http.setHeader('x-app-name', whiteLabel);
    }

    const version = this.getParameterByName('version');
    if (version) {
      http.setHeader('x-app-version', version);
    }

    http.addAsyncRequestTransform(async (request) => {
      if (request.doNotIntercept) {
        return request;
      }

      request.headers = request.headers || {};
      request.headers.Authorization = `Bearer ${this.getParameterByName('token')}`;

      return request;
    });

    window.app = {};
    window.app.setData = this.setData;
    window.app.onMentions = this.onMentions;
    window.app.setFocus = this.setFocus;

    this.defaultViewPort = document.querySelector('meta[name="viewport"]').getAttribute('content');
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no',
      );

    this.onReady();
  }

  componentWillUnmount() {
    for (let [value, resolve] of Object.entries(this.mentions)) {
      resolve(value, []);
    }

    this.mentions = {};
    document.querySelector('[meta name="viewport"]').setAttribute('content', this.defaultViewPort);
  }

  sendMessage = (callBackName, value) => {
    if (window.webkit?.messageHandlers[callBackName]) {
      window.webkit.messageHandlers[callBackName].postMessage(value);
    }

    if (typeof JSBridge !== 'undefined' && JSBridge[callBackName]) {
      JSBridge[callBackName](value);
    }
  };

  onReady = () => {
    this.sendMessage('onReady');
  };

  onChange = (data) => {
    this.sendMessage('onChangeData', JSON.stringify({ ...data, uploading: this.state.uploading }));
    this.setState({ editorData: data });
  };

  onUploading = (uploading) => {
    this.sendMessage('onChangeData', JSON.stringify({ ...this.state.editorData, uploading }));
    this.setState({ uploading });
  };

  setData = (data) => {
    this.setState({
      data,
    });
  };

  setFocus = () => {
    this.setState({
      focus: true,
    });
  };

  getMentions = (value) => {
    if (!value) {
      return [];
    }

    return new Promise((resolve) => {
      this.mentions[value] = resolve;
      this.sendMessage('getMentions', value);
    });
  };

  onMentions = (value, mentions) => {
    const resolve = this.mentions[value];

    if (resolve) {
      resolve(
        (mentions || []).map((user) => ({
          id: `@${user.name}`,
          userId: user.id,
          link: `/members/${user.id}`,
        })),
      );
    }
  };

  render() {
    let toolbar = this.getParameterByName('toolbar');
    if (toolbar) {
      toolbar = toolbar.split(',');
      if (!toolbar.length) {
        toolbar = undefined;
      }
    } else {
      toolbar = [
        'heading',
        'bold',
        'italic',
        'underline',
        'fontColor',
        'numberedList',
        'bulletedList',
        'outdent',
        'indent',
        'link',
        'imageUpload',
        'mediaEmbed',
        'strikethrough',
        'wproofreader',
      ];
    }

    const placeholder = this.getParameterByName('placeholder');

    return (
      <div className="mobile-rich-text">
        <RichTextEditor
          data={this.state.data}
          onChange={this.onChange}
          onUploading={this.onUploading}
          placeholder={placeholder}
          toolbar={toolbar}
          onRequestUpload={uploadImage}
          mentions={this.getMentions}
          isFocusedInitially={this.state.focus}
          isMobile={true}
        />
      </div>
    );
  }
}
