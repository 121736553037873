import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponse, type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';
import { getCurrentUserTimezone } from 'common/utils';
import { type SignUp } from '../../models';
import { signUp } from '../../services';
import { type LoginDataObject } from '../../services/data-objects';

export const useSignUpQuery = () =>
  useMutation<ApiClientResponse<LoginDataObject>, ApiClientResponseError<ResponseErrorDataObject>, SignUp>({
    async mutationFn(auth) {
      const { firstName, lastName, email, password, passwordConfirmation, publicCommunityId, invitationToken } = auth;

      /* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
      const data = {
        body: {
          user: {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            password_confirmation: passwordConfirmation,
            join_community_id: publicCommunityId,
            time_zone: getCurrentUserTimezone(),
          },
        },
        params: {
          invitation_token: invitationToken,
        },
      };
      /* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */

      return signUp(data);
    },
  });
