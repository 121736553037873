import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type LoginDataObject } from './data-objects';

type SignUpRequest = {
  body: {
    user: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      password_confirmation: string;
      join_community_id?: string;
    };
  };
  params?: {
    invitation_token?: string;
  };
};

// TODO: types are not correct, return type is user profile not LoginDataObject
export const signUp = async ({ body, params }: SignUpRequest) => {
  return apiClient.post<LoginDataObject, ApiClientResponse<LoginDataObject>>(routes.signup, body, {
    skipAuth: true,
    params,
  });
};
