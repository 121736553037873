import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { type ReplyDataObject } from 'models/service';
import { bookmarkObject } from '../../services';

export const useBookmarkObjectMutation = () => {
  return useMutation<unknown, { item: { id: string }; reduxItem: ReplyDataObject }>(
    async ({ item }) => bookmarkObject(item.id),
    {
      async onSuccess(_, params) {
        dispatch.feed.storeContentObjectBookmark({ object: params.reduxItem }); // TODO remove this from redux
      },
    },
  );
};
