import { type FormInstance } from 'antd/lib/form';
import { type Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { type Topic } from '../../../../models/program';
import { message, Row, Col, TextInput, SelectInput, DateTimeInput, Title } from '../../../../ui';

type P = {
  form: FormInstance;
  topics: Topic[];
  startTime?: Moment;
  endTime?: Moment;
};

const ActivityFormStep1 = ({ form, topics, startTime, endTime }: P) => {
  const { t } = useTranslation();

  return (
    <div className="activity-form-step-1">
      <Title level={3}>Activity details</Title>
      <SelectInput
        item={{
          name: 'programs_topic_id',
          label: 'Program module',
          rules: [
            {
              required: true,
              message: t('Please select the module'),
            },
          ],
        }}
        input={{
          placeholder: t('Please select the module the activity belongs to'),
          options: topics,
          getOptionLabel: (option) => option.name,
          getOptionValue: (option) => option.id ?? '',
        }}
      />
      <Row item={{ gutter: variables.spaceMd.value }}>
        <Col item={{ span: 12 }}>
          <DateTimeInput
            item={{
              name: 'start_time',
              label: 'Starts on',
              rules: [
                {
                  required: true,
                  message: t('Please provide start date'),
                },
                () => ({
                  async validator(rule, value) {
                    if (value.isBefore(startTime)) {
                      throw 'Activity start time must be set after Program start time';
                    }
                  },
                }),
              ],
              getValueFromEvent(newStartTime: Moment) {
                const endTime = form.getFieldValue('end_time') as Moment;
                if (newStartTime.isAfter(endTime.subtract(60, 'minutes'))) {
                  message.warn('End time was changed to 1 hour later according to start time');
                  form.setFieldsValue({
                    // eslint-disable-next-line @typescript-eslint/naming-convention -- key is defined by Backend API endpoint
                    end_time: newStartTime.clone().add(60, 'minutes'),
                  });
                }

                return newStartTime;
              },
            }}
            input={{
              format: 'MMM D, YYYY [at] h:mm A',
              showTime: { format: 'h:mm A' },
            }}
          />
        </Col>
        <Col item={{ span: 12 }}>
          <DateTimeInput
            item={{
              name: 'end_time',
              label: 'Ends on',
              rules: [
                {
                  required: true,
                  message: t('Please provide end date'),
                },
                () => ({
                  async validator(rule, value) {
                    if (value.isAfter(endTime)) {
                      throw 'Activity end time must be set before Program end time';
                    }
                  },
                }),
              ],
              getValueFromEvent(newEndTime: Moment) {
                const startTime = form.getFieldValue('start_time') as Moment;
                if (newEndTime.isBefore(startTime) || newEndTime.diff(startTime, 'minutes') < 60) {
                  message.warn('End time was changed to 1 hour later according to start time');
                  return startTime.clone().add(60, 'minutes');
                }

                return newEndTime;
              },
            }}
            input={{
              format: 'MMM D, YYYY [at] h:mm A',
              showTime: { format: 'h:mm A' },
            }}
          />
        </Col>
      </Row>
      <TextInput
        item={{
          name: 'name',
          label: 'Activity name',
          rules: [{ required: true, message: t('Activity name is required'), whitespace: true }],
          style: { marginBottom: 0 },
        }}
      />
    </div>
  );
};

export default ActivityFormStep1;
