import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { type ReduxUser } from 'models/user';
import { Avatar, Col, Row, Text } from 'ui';

type P = {
  viewer: ReduxUser;
  onRequestInput: () => void;
};

const CreateCommentFormPlaceholder = ({ viewer, onRequestInput }: P) => {
  return (
    <Row
      item={{
        className: 'create-comment-form-placeholder',
        gutter: [variables.spaceXs.value, variables.spaceXs.value],
      }}
    >
      <Col item={{ flex: '0 1 0' }}>
        <Avatar size="default" photo={viewer.profile_photo?.url ?? images.default.userProfilePhoto} />
      </Col>

      {/* TODO: this has bad accessibility, it is only div */}
      <Col
        item={{
          flex: '1 0 0',
          onClick() {
            onRequestInput();
          },
        }}
      >
        <div className="create-comment-form-placeholder__input">
          <Text>Write comment...</Text>
        </div>
      </Col>
    </Row>
  );
};

export default CreateCommentFormPlaceholder;
