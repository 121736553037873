import { useTranslation } from 'react-i18next';
import { type UploadedFileDataObject } from 'models/service';
import {
  Button,
  Row,
  Col,
  CloseLineIcon,
  PptFileIcon,
  DocumentFileIcon,
  PdfFileIcon,
  SheetFileIcon,
  ImgFileIcon,
  FileDefaultIcon,
} from 'ui';

// TODO: size looks small?
const getFileIcon = (fileFormat: string, className: string) => {
  switch (fileFormat) {
    case 'pptx':
      return <PptFileIcon className={className} />;
    case 'doc':
    case 'docx':
      return <DocumentFileIcon className={className} />;
    case 'pdf':
      return <PdfFileIcon className={className} />;
    case 'csv':
      return <SheetFileIcon className={className} />;
    case 'jpeg':
    case 'png':
      return <ImgFileIcon className={className} />;
    default:
      return <FileDefaultIcon className={className} />;
  }
};

type P = {
  // TODO: should be application models
  attachments?: UploadedFileDataObject[];
  // TODO: should be application models
  onRemove: (attachment: UploadedFileDataObject) => void;
  onClear: () => void;
};

// TODO: fix styling
const Files = ({ attachments, onRemove, onClear }: P) => {
  const { t } = useTranslation();

  return (
    <div className="files">
      <Row>
        <Col item={{ className: 'files__count' }}>
          {/* TODO: fix this and add real collapse */}
          {/* <DownLine /> */}
          {t('Attachments ({{count}})', { count: attachments?.length })}
        </Col>
        <Col item={{ className: 'files__clear' }}>
          <Button type="link" onClick={onClear}>
            Clear all
          </Button>
        </Col>
      </Row>
      {attachments?.map((attachment) => {
        const splitFileName = attachment.filename.split('.');
        // TODO: use attachment.content_type instead
        const fileFormat = splitFileName[splitFileName.length - 1];

        return (
          <Row key={attachment.id} item={{ className: 'files__row' }}>
            <Col>
              {/* TODO: text style? */}
              {getFileIcon(fileFormat, 'files__row__icon')}
              {attachment.filename}
            </Col>
            <Col>
              <Button
                type="link-secondary"
                onClick={() => {
                  onRemove(attachment);
                }}
                icon={<CloseLineIcon />}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default Files;
