import { useQueryClient } from '@tanstack/react-query';
import { GoogleService } from 'old/services/GoogleService';
import { useMutation } from 'query';
import { getCalendarSyncQueryKey } from '..';
import { createCalendarSync } from '../../services';

export const useCalendarGoogleLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, { redirectUrl?: string }, Error>(
    async ({ redirectUrl }) => {
      const responseGoogle = await GoogleService.login({
        scope: ['https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/calendar.events'],
        // eslint-disable-next-line @typescript-eslint/naming-convention -- We missing correct application model in camelCase, we need to fix it
        access_type: 'offline',
        redirectUri: redirectUrl,
      });
      const respone = await createCalendarSync(responseGoogle);

      return respone.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getCalendarSyncQueryKey());
      },
    },
  );
};
