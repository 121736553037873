import { type CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { getAppConfig } from 'old/atoms/app-config-gate';
import { dispatch } from 'old/store';
import variables from 'common/styles/variables.json';
import { Row, Col, message } from 'ui';

type P = {
  onSuccessLogin: () => void;
};

export const SocialButtons = ({ onSuccessLogin }: P) => {
  const { constants } = getAppConfig();

  const onSuccess = async (response: CredentialResponse) => {
    try {
      await dispatch.session.attemptWithSocial({ code: response.credential, provider: 'google_v2' });

      onSuccessLogin();
    } catch (error) {
      console.error('Google Sign-in failed:', error);
    }
  };

  const onError = () => {
    message.error('Failed to connect to Google account.');
  };

  return (
    <Row item={{ gutter: variables.spaceMd.value, justify: 'center' }}>
      {constants.GOOGLE_ID ? (
        <Col>
          <GoogleLogin onSuccess={onSuccess} onError={onError} logo_alignment="center" />
        </Col>
      ) : null}
    </Row>
  );
};
