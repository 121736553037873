import { CopyOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { copyToClipboard } from 'common/utils';
import {
  Button,
  Col,
  Divider,
  Form,
  isEmail,
  message,
  Modal,
  ModalFormActions,
  ReactMultiEmail,
  Row,
  type Rule,
  Section,
  Tag,
  Title,
} from 'ui';

import { useInviteUsers } from '../../queries';
import { getInvToken } from '../../services/getInvToken';
import { useValidateUsers, type ValidationType } from './hooks';
import { UsersInput } from './UsersInput';

type FormValues = {
  users: string[];
  phones: string[];
  emails: string[];
};

type P = {
  communityId: string;
  isOpened: boolean;
  onClose: () => void;
};

const MembersInviteModal = ({ communityId, isOpened, onClose }: P) => {
  const { t } = useTranslation();
  const [loadingToken, setLoadingToken] = useState(false);
  const { mutate: inviteUsers, isLoading: isInvitingUsers } = useInviteUsers();
  const { isLoading: isValidatingUsers, validate: validateUser } = useValidateUsers();

  const onFinish = async (values: FormValues) => {
    if (values.emails.length === 0 && values.phones.length === 0 && values.users.length === 0) {
      message.error('Please, invite some users.');
      return;
    }

    inviteUsers(
      {
        id: communityId,
        invitees: { emails: values.emails, phones: values.phones, userIds: values.users },
      },
      {
        onSuccess() {
          onClose();
        },
      },
    );
  };

  const generateValidationRule = useCallback(
    (validatationType: ValidationType): Rule => ({
      async validator(_, values: string[]) {
        const promises = values.map(async (value) => validateUser({ type: validatationType, value }));

        const validations = await Promise.all(promises);
        const invalidEmails = validations
          .filter((validation) => !validation.valid)
          .map((validation) => validation.value);

        if (invalidEmails.length > 0) {
          throw t('multiFieldInviteError', { count: invalidEmails.length, items: invalidEmails.join(', ') });
        }
      },
    }),
    [t, validateUser],
  );

  const handleTokenClipboard = async () =>
    copyToClipboard(async () => {
      setLoadingToken(true);
      const response = await getInvToken({ communityId });
      const token = `${window.location.origin}/communities/${communityId}?invitation_token=${response.data.token}`;
      setLoadingToken(false);
      return token;
    });

  return (
    <Modal
      title={'Invite'}
      isOpened={isOpened}
      onCancel={() => {
        onClose();
      }}
      disableBack
      destroyOnClose
    >
      <Form<FormValues>
        className="community-members-invite"
        onFinish={onFinish}
        initialValues={{ emails: [], users: [], phones: [] }}
      >
        <Row item={{ justify: 'space-between', align: 'middle' }}>
          <Col>
            <Title level={5} className="community-members-invite__title">
              {t('Get shareable link to invite members')}
            </Title>
          </Col>
          <Col>
            <Button
              icon={<CopyOutlined />}
              loading={loadingToken}
              onClick={async () => handleTokenClipboard()}
              size="large"
            >
              {t('Copy Invite Link')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <Section paddingBottom={false} paddingTop={false}>
          <Form.Item name="users" label={t('Invite Registered Members')}>
            <UsersInput autoClearSearchValue communityId={communityId} />
          </Form.Item>
          <Form.Item
            name="emails"
            valuePropName="emails"
            label={t('Invite Non-registered Users')}
            rules={[generateValidationRule('email')]}
          >
            <ReactMultiEmail
              placeholder={t('E-mail addresses')}
              className="community-members-invite__multi-email"
              validateEmail={(email) => isEmail(email)}
              getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                return (
                  <Tag
                    key={email}
                    closable
                    onClose={() => {
                      removeEmail(index);
                    }}
                  >
                    {email}
                  </Tag>
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="phones"
            valuePropName="emails"
            label={t('Invite via phone number')}
            rules={[generateValidationRule('phone')]}
          >
            <ReactMultiEmail
              placeholder={t('Phone numbers')}
              className="community-members-invite__multi-email"
              enableSearchOnBackSpace={false}
              validateEmail={(phoneNumber) => {
                if (!isValidPhoneNumber(phoneNumber)) {
                  message.error(t('notValidPhone', { phoneNumber }));
                }

                return isValidPhoneNumber(phoneNumber);
              }}
              getLabel={(phone: string, index: number, removePhone: (index: number) => void) => {
                return (
                  <Tag
                    key={phone}
                    closable
                    onClose={() => {
                      removePhone(index);
                    }}
                  >
                    {phone}
                  </Tag>
                );
              }}
            />
          </Form.Item>
        </Section>
        <ModalFormActions
          left={{
            onClick() {
              onClose();
            },
            children: t('Close'),
          }}
          submit={{
            children: t('Invite Selected'),
            id: 'invite-members',
            loading: isValidatingUsers || isInvitingUsers,
          }}
        />
      </Form>
    </Modal>
  );
};

export default MembersInviteModal;
