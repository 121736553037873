import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';
import { validator } from '../../utils/validator';
import { Box } from '../box';
import { Button } from '../button';
import { ThemeConsumer } from '../theme';
import { CropperEditorField, SelectField, TextAreaField, TextField, UploadFileField } from '../form';
import { withPermissions } from '../auth';
import { RequestError } from '../error';
import { Text } from '../text';
import { Screenshots } from './form-elements/Screenshots';
import { ToggleWithLabel } from '../form/base-atoms/ToggleWithLabel';
import { FEATURE_FLAGS, getInitialValues, getValidationRules } from './helpers';
import { Col, Row, Link, RichTextEditor } from 'ui';
import { uploadImage } from 'common/queries';

export class Renderer extends React.Component {
  state = {
    response: null,
    status: 'draft',
  };

  onSubmit = async (formik, values) => {
    formik.setSubmitting(true);

    this.setState({ response: null });

    const result = {
      ...values,
      organization_name: this.props.organizations.find((org) => org.id === values.organization_name)?.name,
      status: this.state.status,
    };

    const response = await this.props.onSubmit(result);
    this.setState({ response: response });

    if (response?.ok) {
      this.props.onSuccess(response);
      formik.resetForm(values);
    } else {
      this.props.onError();
    }

    formik.setSubmitting(false);
  };

  deleteAppConfig = async () => {
    if (window.confirm(this.props.t(`You are about to delete this app configuration. Are you sure?`))) {
      this.props.onDelete();
    }
  };

  renderButtons = (formik, theme) => (
    <Box flex="1" flexDirection="row" justifyContent="space-between" style={{ margin: '30px 0', width: '100%' }}>
      {!(this.props.appConfig && this.props.appConfig.status === 'finished') && (
        <Button
          size="lg"
          title={<Trans>Save Draft</Trans>}
          type="submit"
          color={theme.color.brand}
          onClick={(e) => {
            e.preventDefault();

            if (Object.keys(formik.errors).length > 0) {
              const errorKeys = Object.keys(formik.errors);

              if (document.getElementsByName(errorKeys[0])[0]) {
                document.getElementsByName(errorKeys[0])[0].focus();
              } else {
                window.scrollTo(0, 0);
              }
            }

            this.setState({ status: 'draft' }, () => formik.submitForm());
          }}
          textColor="white"
          style={{
            padding: '0 40px',
          }}
        />
      )}
      <Button
        size="lg"
        title={<Trans>Publish</Trans>}
        type="submit"
        color={theme.color.brand}
        onClick={async (e) => {
          e.preventDefault();

          if (Object.keys(formik.errors).length > 0) {
            const errorKeys = Object.keys(formik.errors);

            if (document.getElementsByName(errorKeys[0])[0]) {
              document.getElementsByName(errorKeys[0])[0].focus();
            } else {
              window.scrollTo(0, 0);
            }
          }

          this.setState({ status: 'finished' }, () => formik.submitForm());
        }}
        textColor="white"
        style={{
          padding: 'padding 0 40px',
        }}
      />
      {this.props.onDelete &&
        withPermissions(
          [{ scope: 'superadmin' }],
          <Button
            size="lg"
            title="Delete"
            type="submit"
            color={theme.color.red}
            onClick={(e) => {
              e.preventDefault();
              this.deleteAppConfig();
            }}
            textColor="white"
            style={{
              padding: 'padding 0 40px',
            }}
          />,
          null,
        )}
    </Box>
  );

  render() {
    const appConfig = this.props.appConfig || {};

    return (
      <Formik
        enableReinitialize
        validate={(values, props) => {
          return validator.validateAll(values, getValidationRules(this.state.status === 'finished'));
        }}
        initialValues={getInitialValues(appConfig)}
        onSubmit={(values, formik) => this.onSubmit(formik, values)}
      >
        {(formik) => (
          <Form>
            {appConfig.updated_at && (
              <div className="wlf__updated">
                {this.props.t('configUpdatedAt', { date: new Date(appConfig.updated_at) })}
              </div>
            )}
            <ThemeConsumer>
              {(theme) => (
                <React.Fragment>
                  <Prompt
                    when={formik.dirty && formik.submitCount === 0}
                    message={(location) => this.props.t('Do you wish to discard changes?')}
                  />
                  <div className="wlf__section">
                    <h2 className="wlf__heading">BigHeart Inputs</h2>

                    <TextField name="app_name" placeholder="App name" label={<Trans>App name</Trans>} />
                    <TextField name="app_domain" placeholder="App domain" label={<Trans>App domain</Trans>} />
                    <Text size={12} color="#90A4AE" component="div" style={{ marginBottom: 16 }}>
                      <Trans>Allowed: http://example.com or https://example.com</Trans>
                    </Text>
                    <TextField name="app_email" placeholder="App email" label={<Trans>App email</Trans>} />
                    <TextField
                      name="app_color"
                      placeholder="App color (e.g. #FAFAFA)"
                      label={<Trans>App color</Trans>}
                    />
                    <TextField
                      name="landing_summary"
                      placeholder="Landing summary"
                      label={<Trans>Landing summary</Trans>}
                    />
                    <TextField
                      name="landing_description"
                      placeholder="Landing description"
                      label={<Trans>Landing description</Trans>}
                    />
                    <TextField
                      name="dropbox_api_key"
                      placeholder="Dropbox api key"
                      label={<Trans>Dropbox api key</Trans>}
                    />
                    <TextField name="ios_app_url" placeholder="iOS app url" label={<Trans>iOS app url</Trans>} />
                    <TextField
                      name="android_app_url"
                      placeholder="Android app url"
                      label={<Trans>Android app url</Trans>}
                    />
                    <TextField name="invite_text" placeholder="Invite text" label={<Trans>Invite text</Trans>} />
                    
                    <TextField name="terms_url" placeholder="Terms url" label={<Trans>Terms url</Trans>} />
                    
                    {/* TODO START this is hacked, temporary solution */}
                    <Text
                      weight="100"
                      size="12px"
                      lineHeight="14px"
                      component="div"
                      style={{ padding: '0 5px' }}
                    >
                      <Trans>Privacy Policy content</Trans>
                    </Text>
                    <RichTextEditor
                      data={formik.values.privacy_policy?.html_content || ''}
                      onRequestUpload={uploadImage}
                      onChange={(value) => formik.setFieldValue('privacy_policy.html_content', value.html)}
                    />
                    {appConfig.name === 'default' ? null : (
                      <div style={{ marginTop: 20 }}>
                        <Field name="privacy_policy.use_default">
                          {(insideFormik) => (
                            <ToggleWithLabel
                              label={<><Trans>Use content of Privacy Policy from</Trans>{' '}<Link to="/admin/app-configs/default/update"><Trans>default WLA</Trans></Link></>}
                              name="privacy_policy.use_default"
                              inputProps={{
                                ...insideFormik.field,
                                checked: insideFormik.field.value,
                                onChange: (event) => {
                                  insideFormik.field.onChange({
                                    target: {
                                      name: event.target.name,
                                      value: event.target.checked,
                                    },
                                  });
                                },
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    {/* TODO END this is hacked, temporary solution */}

                    <div style={{ marginTop: 20 }}>
                      <SelectField
                        name="organization_name"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        label={<Trans>Organization name</Trans>}
                        options={this.props.organizations.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        placeholder={this.props.t('Please, select an organization')}
                      />
                    </div>
                    <TextField
                      name="organization_website"
                      placeholder="Organization website"
                      label={<Trans>Organization website</Trans>}
                    />
                    <TextField
                      name="organization_address"
                      placeholder="Organization address"
                      label={<Trans>Organization address</Trans>}
                    />
                    <Text size={24} lineHeight={1.2} color="#90A4AE" component="div" style={{ margin: '30px 0' }}>
                      <Trans>Mobile</Trans>
                    </Text>

                    <Text size={24} lineHeight={1.2} color="#90A4AE" component="div" style={{ margin: '30px 0' }}>
                      <Trans>Mobile Stores</Trans>
                    </Text>
                    <TextField
                      name="app_store_name"
                      placeholder="App store name (max 30 characters)"
                      label={<Trans>App store name</Trans>}
                    />
                    <TextField
                      name="app_store_subtitle"
                      placeholder="App store subtitle (max 30 characters)"
                      label={<Trans>App store subtitle</Trans>}
                    />
                    <TextAreaField
                      name="app_store_description"
                      placeholder="App store description"
                      label={<Trans>App store description</Trans>}
                    />
                    <TextField
                      name="app_store_category"
                      placeholder="App store category"
                      label={<Trans>App store category</Trans>}
                    />
                    <Text size={12} color="#90A4AE" component="div" style={{ marginBottom: 30 }}>
                      <Trans>List of</Trans>
                      <a
                        href="https://developer.apple.com/app-store/categories/"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          marginLeft: 5,
                          color: theme.color.brand,
                        }}
                      >
                        <Trans> available categories</Trans>
                      </a>
                    </Text>
                    <TextField
                      name="google_play_name"
                      placeholder="Google play name (max 30 characters)"
                      label={<Trans>Google play subtitle</Trans>}
                    />
                    <TextField
                      name="google_play_short_description"
                      placeholder="Google play short description (max 80 characters)"
                      label={<Trans>Google play short description</Trans>}
                    />
                    <TextAreaField
                      name="google_play_full_description"
                      placeholder="Google play full description (max 4000 characters)"
                      label={<Trans>Google play full description</Trans>}
                    />
                    <TextField
                      name="google_play_category"
                      placeholder="Google play category"
                      label={<Trans>Google play category</Trans>}
                    />
                    <Text size={12} color="#90A4AE" component="div" style={{ marginBottom: 30 }}>
                      <Trans>List of</Trans>
                      <a
                        href="https://support.google.com/googleplay/android-developer/answer/113475?hl=en"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          marginLeft: 5,
                          color: theme.color.brand,
                        }}
                      >
                        <Trans> available categories</Trans>
                      </a>
                    </Text>
                    <Text size={24} lineHeight={1.2} color="#90A4AE" component="div" style={{ margin: '30px 0' }}>
                      <Trans>Optional fields</Trans>
                    </Text>
                    <TextField name="facebook_url" placeholder="Facebook url" label={<Trans>Facebook url</Trans>} />
                    <TextField name="twitter_url" placeholder="Twitter url" label={<Trans>Twitter url</Trans>} />
                    <TextField name="linkedin_url" placeholder="Linkedin url" label={<Trans>Linkedin url</Trans>} />
                    <TextField name="vimeo_url" placeholder="Vimeo url" label={<Trans>Vimeo url</Trans>} />
                    <TextField name="instagram_url" placeholder="Instagram url" label={<Trans>Instagram url</Trans>} />

                    <Text size={24} lineHeight={1.2} color="#90A4AE" component="div" style={{ margin: '30px 0' }}>
                      <Trans>Images</Trans>
                    </Text>
                    <Box flexDirection="row" justifyContent="space-around" style={{ marginTop: 20 }}>
                      <div className="col-sm-4">
                        <CropperEditorField
                          name="web_icon"
                          labelAlign="center"
                          label={<Trans>Web favicon (512x512)</Trans>}
                          cropType="favicon"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={512}
                          imageHeight={512}
                          imageErrorMessage={<Trans>Image has to be (512x512)</Trans>}
                        />
                      </div>
                      <div className="col-sm-4">
                        <CropperEditorField
                          name="web_logo_dark"
                          labelAlign="center"
                          label={<Trans>Web Logo dark (940x230)</Trans>}
                          cropType="logo"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={940}
                          imageHeight={230}
                          imageErrorMessage={<Trans>Image has to be (940x230)</Trans>}
                        />
                      </div>
                      <div className="col-sm-4">
                        <CropperEditorField
                          name="web_logo_light"
                          labelAlign="center"
                          label={<Trans>Web Logo light (940x230)</Trans>}
                          cropType="logo"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={940}
                          imageHeight={230}
                          imageErrorMessage={<Trans>Image has to be (940x230)</Trans>}
                        />
                      </div>
                    </Box>
                    <div style={{ marginTop: 20 }}>
                      <CropperEditorField
                        name="web_landing_image"
                        label={<Trans>Web Landing Image (2750x1775)</Trans>}
                        cropType="landing"
                        skipCropper={true}
                        downloadable={true}
                        imageWidth={2750}
                        imageHeight={1775}
                        imageErrorMessage={<Trans>Image has to be (2750x1775)</Trans>}
                      />
                    </div>
                    <Box flexDirection="row" justifyContent="space-around" style={{ marginTop: 20 }}>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_vector_logo_launch_screen"
                          label={<Trans>Mobile vector logo launch screen (1024xY png)</Trans>}
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={1024}
                          imageErrorMessage={<Trans>Image has to be (1024xY)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_vector_logo"
                          label={<Trans>Mobile vector logo (1024x1024 png)</Trans>}
                          cropType="mobile_vector_logo"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={1024}
                          imageHeight={1024}
                          imageErrorMessage={<Trans>Image has to be (1024x1024)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_logo"
                          label={<Trans>Mobile logo (705xY png)</Trans>}
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={705}
                          imageErrorMessage={<Trans>Image has to be (705xY)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_logo_white"
                          label={<Trans>Mobile logo white (705xY png)</Trans>}
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={705}
                          imageErrorMessage={<Trans>Image has to be (705xY)</Trans>}
                        />
                      </div>
                    </Box>
                    <Box flexDirection="row" justifyContent="space-around" style={{ marginTop: 20 }}>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_launch_image"
                          label={<Trans>Mobile launch image (705xY png)</Trans>}
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={705}
                          imageErrorMessage={<Trans>Image has to be (705xY)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_ic_launcher_web"
                          label={<Trans>Mobile ic launcher web (512x512 png)</Trans>}
                          cropType="favicon"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={512}
                          imageHeight={512}
                          imageErrorMessage={<Trans>Image has to be (512x512)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_launch_screen"
                          label={<Trans>Mobile launch screen (940xY png required only in white color)</Trans>}
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={940}
                          imageErrorMessage={<Trans>Image has to be (940xY)</Trans>}
                        />
                      </div>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="mobile_splash_icon"
                          label={<Trans>Mobile splash icon (320x320)</Trans>}
                          cropType="favicon"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={320}
                          imageHeight={320}
                          imageErrorMessage={<Trans>Image has to be (320x320)</Trans>}
                        />
                      </div>
                    </Box>
                    <Box flexDirection="row" justifyContent="start" style={{ marginTop: 20 }}>
                      <div className="col-sm-3">
                        <CropperEditorField
                          name="landscape_icon"
                          label={<Trans>Landscape icon (1372x192)</Trans>}
                          cropType="favicon"
                          skipCropper={true}
                          downloadable={true}
                          imageWidth={1372}
                          imageHeight={192}
                          imageErrorMessage={<Trans>Image has to be (1372x192)</Trans>}
                        />
                      </div>
                    </Box>
                    <Box flexDirection="column" style={{ marginTop: 20 }}>
                      <Text size={20} color="#90A4AE" component="div" style={{ marginTop: 30 }}>
                        <Trans>App store 5.5 screenshots</Trans>
                      </Text>
                      <Text size={14} color="#90A4AE" component="div" style={{ margin: '15px 0' }}>
                        <Trans>Size 1242x2208, min 3, max 10</Trans>
                      </Text>
                      <Screenshots
                        formikElement={formik.values.app_store_screenshots_55}
                        name="app_store_screenshots_55"
                        min={3}
                        max={10}
                        cropType="iphone_55"
                        skipCropper={true}
                        downloadable={true}
                        imageWidth={1242}
                        imageHeight={2208}
                        imageErrorMessage={<Trans>Image has to be (1242x2208)</Trans>}
                      />
                    </Box>
                    <Box flexDirection="column" style={{ marginTop: 20 }}>
                      <Text size={20} color="#90A4AE" component="div" style={{ marginTop: 30 }}>
                        <Trans>App store 6.5 screenshots</Trans>
                      </Text>
                      <Text size={14} color="#90A4AE" component="div" style={{ margin: '15px 0' }}>
                        <Trans>Size 1242x2688, min 3, max 10</Trans>
                      </Text>
                      <Screenshots
                        formikElement={formik.values.app_store_screenshots_65}
                        name="app_store_screenshots_65"
                        min={3}
                        max={10}
                        cropType="iphone_65"
                        skipCropper={true}
                        downloadable={true}
                        imageWidth={1242}
                        imageHeight={2688}
                        imageErrorMessage={<Trans>Image has to be (1242x2688)</Trans>}
                      />
                    </Box>
                    <Box flexDirection="column" style={{ marginTop: 20 }}>
                      <Text size={20} color="#90A4AE" component="div" style={{ marginTop: 30 }}>
                        <Trans>App store iPad screenshots</Trans>
                      </Text>
                      <Text size={14} color="#90A4AE" component="div" style={{ margin: '15px 0' }}>
                        <Trans>Size 2048x2732, min 3, max 10</Trans>
                      </Text>
                      <Screenshots
                        formikElement={formik.values.app_store_ipad_screenshots}
                        name="app_store_ipad_screenshots"
                        min={3}
                        max={10}
                        cropType="ipad"
                        skipCropper={true}
                        downloadable={true}
                        imageWidth={2048}
                        imageHeight={2732}
                        imageErrorMessage={<Trans>Image has to be (2048x2732)</Trans>}
                      />
                    </Box>
                    <div style={{ marginTop: 20 }}>
                      <CropperEditorField
                        name="google_play_graphic"
                        label={<Trans>Google play graphic (1024x500)</Trans>}
                        cropType="google_graphic"
                        skipCropper={true}
                        downloadable={true}
                        imageWidth={1024}
                        imageHeight={500}
                        imageErrorMessage={<Trans>Image has to be (1024x500)</Trans>}
                      />
                    </div>
                    <Box flexDirection="column" style={{ marginTop: 20 }}>
                      <Text size={20} color="#90A4AE" component="div" style={{ marginTop: 30, lineHeight: '1.2' }}>
                        <Trans>
                          Google play screenshots (Minimum dimension: 320px, Maximum dimension: 3840px, must maintain a
                          height/width ratio of 2:1)
                        </Trans>
                      </Text>
                      <Text size={14} color="#90A4AE" component="div" style={{ margin: '15px 0' }}>
                        <Trans>Size 1920x3840, min 2, max 8</Trans>
                      </Text>
                      <Screenshots
                        formikElement={formik.values.google_play_screenshots}
                        name="google_play_screenshots"
                        min={2}
                        max={8}
                        cropType="google_phone"
                        skipCropper={true}
                        downloadable={true}
                        dimension={{ max: 3840, min: 320 }}
                        aspectRatio={{ height: 2, width: 1 }}
                        imageErrorMessage={
                          <Trans>
                            Image has to be Minimum dimension: 320px, Maximum dimension: 3840px, must maintain a
                            height/width ratio of 2:1
                          </Trans>
                        }
                      />
                    </Box>
                  </div>
                  <div className="wlf__section">
                    <h2 className="wlf__heading">T4IT Inputs</h2>

                    <TextField name="name" placeholder="App Config name" label={<Trans>App config name</Trans>} />
                    <Text size={12} color="#90A4AE" component="div" style={{ marginBottom: 16 }}>
                      <Trans>
                        Allowed: lowercase characters, numbers. Not allowed: spaces, special characters except
                        underscore
                      </Trans>
                    </Text>
                    <TextField name="app_store_id" placeholder="App store ID" label={<Trans>App store ID</Trans>} />
                    <TextField
                      name="google_client_web_id"
                      placeholder="Google client web id"
                      label={<Trans>Google client id</Trans>}
                    />
                    <TextField
                      name="google_client_web_secret"
                      placeholder="Google client web secret"
                      label={<Trans>Google client secret</Trans>}
                    />
                    <TextField
                      name="google_developer_key"
                      placeholder="Google developer key"
                      label={<Trans>Google developer key</Trans>}
                    />
                    <TextField
                      name="google_analytics_id"
                      placeholder="Google analytics tracking id"
                      label={<Trans>Google analytics tracking id</Trans>}
                    />
                    <TextField
                      name="apple_pay_merchant"
                      placeholder="Apple pay merchant"
                      label={<Trans>Apple pay merchant</Trans>}
                    />
                    <TextField
                      name="braintree_merchant_id"
                      placeholder="Braintree merchant id"
                      label={<Trans>Braintree merchant id</Trans>}
                    />
                    <TextField
                      name="braintree_tokenization_key"
                      placeholder="Braintree tokenization key"
                      label={<Trans>Braintree tokenization key</Trans>}
                    />
                    <TextField
                      name="app_url_scheme"
                      placeholder="App url scheme"
                      label={<Trans>App url scheme</Trans>}
                    />
                    <TextField
                      name="google_client_ios_id"
                      placeholder="Google client ios id"
                      label={<Trans>Google client id</Trans>}
                    />
                    <TextField
                      name="google_client_android_id"
                      placeholder="Google client android id"
                      label={<Trans>Google client id</Trans>}
                    />
                    <TextField
                      name="cloudfront_distribution_id"
                      placeholder="Cloudfront distribution ID"
                      label={<Trans>Cloudfront distribution ID</Trans>}
                    />
                    <TextField name="fcm_server_key" placeholder="FCM token" label={<Trans>FCM token</Trans>} />
                    <TextField
                      name="outlook_client_id"
                      placeholder="Outlook client id"
                      label={<Trans>Outlook client id</Trans>}
                    />
                    <TextField
                      name="outlook_client_secret"
                      placeholder="Outlook client secret"
                      label={<Trans>Outlook client secret</Trans>}
                    />
                    <TextField
                      name="bundle_identifier"
                      placeholder="Bundle identifier"
                      label={<Trans>Bundle identifier</Trans>}
                    />
                    <TextField name="node_api_url" placeholder="Node api url" label={<Trans>Node api url</Trans>} />
                    <Row item={{ gutter: 16, justify: 'center', align: 'middle', className: 'wlf__file-upload' }}>
                      <Col item={{ span: 20 }}>
                        <Text size={17} color="#90A4AE" component="div" style={{ marginRight: 15 }}>
                          <Trans>Firebase plist iOS</Trans>
                        </Text>
                      </Col>
                      <Col item={{ span: 4 }}>
                        <UploadFileField name="firebase_plist_ios" downloadable={true} suffixId="firebase-plist-ios" />
                      </Col>
                    </Row>
                    <Row item={{ gutter: 16, justify: 'center', align: 'middle', className: 'wlf__file-upload' }}>
                      <Col item={{ span: 20 }}>
                        <Text size={17} color="#90A4AE" component="div" style={{ marginRight: 15 }}>
                          <Trans>Google services JSON</Trans>
                        </Text>
                      </Col>
                      <Col item={{ span: 4 }}>
                        <UploadFileField name="google_services" downloadable={true} suffixId="google-services" />
                      </Col>
                    </Row>
                    <Text size={24} lineHeight={1.2} color="#90A4AE" component="div" style={{ margin: '30px 0' }}>
                      <Trans>Feature Flags</Trans>
                    </Text>
                    {FEATURE_FLAGS.map((flag, index) => (
                      <div key={index} style={{ marginBottom: 15 }}>
                        <Field name={`feature_flags.${flag.name}`}>
                          {(insideFormik) => (
                            <ToggleWithLabel
                              label={<Trans>{flag.label}</Trans>}
                              name={`feature_flags.${flag.name}`}
                              inputProps={{
                                ...insideFormik.field,
                                checked: insideFormik.field.value,
                                onChange: (event) => {
                                  insideFormik.field.onChange({
                                    target: {
                                      name: event.target.name,
                                      value: event.target.checked,
                                    },
                                  });
                                },
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    ))}
                  </div>
                  {this.state.response ? <RequestError response={this.state.response} /> : null}
                  {this.renderButtons(formik, theme)}
                </React.Fragment>
              )}
            </ThemeConsumer>
          </Form>
        )}
      </Formik>
    );
  }
}

export const AppConfigForm = withTranslation()(Renderer);
