import { type Reply } from 'models';
import { type ComponentProps } from 'react';
import { type AutocompleteUser, type ReduxUser } from 'models/user';
import { useEditCommentMutation } from '../../queries/mutations';
import CommentForm, { type CommentFormValues } from '../CommentForm';

const initFormValues = (reply: Reply): ComponentProps<typeof CommentForm>['initialValues'] => {
  return {
    htmlContent: reply.htmlContent,
    textContent: reply.textContent,
    attachments: { loading: false, files: reply.attachments ?? [] },
  };
};

type P = {
  viewer: ReduxUser;
  comment: Reply;
  onRequestMentions: (value: string) => Promise<AutocompleteUser[]>;
  onSuccess: () => void;
};

const EditCommentForm = ({ viewer, comment, onRequestMentions, onSuccess }: P) => {
  const { isLoading: isEditingComment, mutate: editComent } = useEditCommentMutation();

  const onFinish = async (values: CommentFormValues) => {
    editComent(
      {
        id: comment.id,
        parentId: comment.parentId,
        textContent: values.textContent,
        htmlContent: values.htmlContent,
        attachments: values.attachments.files,
      },
      {
        onSuccess() {
          onSuccess();
        },
      },
    );
  };

  return (
    <CommentForm
      onSubmit={onFinish}
      viewer={viewer}
      onRequestMentions={onRequestMentions}
      isLoading={isEditingComment}
      initialValues={initFormValues(comment)}
      avatar={false}
    />
  );
};

export default EditCommentForm;
