import { type Community } from 'models';
import { useTranslation } from 'react-i18next';
import { Form, Modal, ModalFormActions, RichTextInput, Section } from 'ui';
import { useUpdateCommunity } from '../../../queries';

type FormValues = {
  htmlDescription: string;
  description: string;
};

type P = {
  community: Community;
  isOpened: boolean;
  onClose: () => void;
};

const EditModal = ({ community, isOpened, onClose }: P) => {
  const { t } = useTranslation();
  const { mutate: updateCommunity, isLoading: isUpdatingCommunity } = useUpdateCommunity();

  const onFinish = async (values: FormValues) => {
    updateCommunity(
      {
        id: community.id,
        data: { description: values.description, htmlDescription: values.htmlDescription },
      },
      {
        onSuccess() {
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      title={'Edit Description'}
      isOpened={isOpened}
      onCancel={() => {
        onClose();
      }}
      disableBack
      destroyOnClose
    >
      <Form<FormValues>
        onFinish={onFinish}
        initialValues={{ htmlDescription: community.htmlDescription, description: community.description }}
      >
        <Section paddingBottom={false} paddingTop={false}>
          <RichTextInput
            text={{ name: 'description' }}
            html={{
              name: 'htmlDescription',
              label: 'Description',
            }}
          />
        </Section>
        <ModalFormActions
          left={{
            onClick() {
              onClose();
            },
            children: t('Close'),
          }}
          submit={{
            children: t('Save'),
            id: 'edit-community-description-btn',
            loading: isUpdatingCommunity,
          }}
        />
      </Form>
    </Modal>
  );
};

export default EditModal;
