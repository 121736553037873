import { type Entity } from 'models';
import { type Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';
import { type PostInCommunity } from 'models/poll';
import type Poll from 'models/poll';
import type Program from 'models/program';
import {
  AttachmentsInput,
  message,
  Form,
  TextInput,
  SelectInput,
  RichTextInput,
  DateTimeInput,
  Section,
  ModalFormActions,
  Row,
  Col,
  Title,
  generateAttachmentsInputInitialValue,
} from 'ui';

import PollQuestions from './features/PollQuestions';

type P = {
  onSubmit: (poll: Poll) => void | void;
  program?: Program;
  poll: Poll;
  communities: Entity[];
  edit?: boolean;
};

export type ProgramTopic = {
  id: string;
  name: string;
};

const defaultQuestion = () => ({ question: '', multiple: true, choices: [{ name: '' }] });

/* eslint-disable @typescript-eslint/naming-convention -- Start: We missing correct application model in camelCase, we need to fix it */
const getInitialValues = ({ post_in_communities, ...poll }: Poll) => {
  return {
    ...poll,
    community_id: post_in_communities?.map((community: PostInCommunity) => community.id)[0],
    questions: poll.questions.length > 0 ? poll.questions : [defaultQuestion()],
    attachments: generateAttachmentsInputInitialValue(poll.attachments),
  };
};

const PollForm = ({ onSubmit, poll, program, communities, edit }: P) => {
  const [form] = Form.useForm();
  const isProgram = Boolean(program);
  const initialValues = getInitialValues(poll);
  const { t } = useTranslation();

  const onFinish = (data: any) => {
    const cleanedPoll = {
      ...poll,
      ...data,
      start_time: isProgram ? data.start_time : undefined,
    };

    delete cleanedPoll.post_in_communities;

    if (data.attachments) {
      const fileList = Array.isArray(data.attachments) ? data.attachments : data.attachments.fileList;
      cleanedPoll.attachments = fileList.map((file: any) => file.response || file);
    }

    onSubmit(cleanedPoll);
  };

  return (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <Section paddingTop={false} paddingBottom={false}>
        <Title level={3}>Poll details</Title>
        {program ? (
          <>
            <TextInput item={{ name: 'program_id', hidden: true }} />
            <SelectInput<any>
              item={{
                name: 'programs_topic_id',
                label: 'Program module',
                rules: [
                  {
                    required: true,
                    message: t('Please select the module'),
                  },
                ],
                'data-test-id': 'select_course-topic',
              }}
              input={{
                placeholder: t('Please select the module the poll belongs to'),
                // TODO these are not same types! there is any now for this case
                options: program.topics,
                getOptionLabel: (option: ProgramTopic) => option.name,
                getOptionValue: (option: ProgramTopic) => option.id,
              }}
            />
          </>
        ) : null}
        {!isProgram && edit ? (
          <SelectInput
            item={{
              name: 'community_id',
              label: 'Select community',
              rules: [
                {
                  required: true,
                },
              ],
              'data-test-id': 'select_communities',
            }}
            input={{
              placeholder: t('Select community'),
              options: communities,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            }}
          />
        ) : null}
        <Row item={{ gutter: variables.spaceMd.value }}>
          {isProgram ? (
            <Col item={{ span: 12 }}>
              <DateTimeInput
                item={{
                  name: 'start_time',
                  label: 'Starts on',
                  rules: [
                    {
                      required: true,
                      message: t('Please provide start date'),
                    },
                    () => ({
                      // eslint-disable-next-line @typescript-eslint/promise-function-async
                      validator(_, value) {
                        if (isProgram && value.isBefore(program?.start_time)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Poll start time must be set after Program start time');
                        }

                        return Promise.resolve();
                      },
                    }),
                  ],
                  getValueFromEvent(newStartTime: Moment) {
                    const endTime = form.getFieldValue('end_time') as Moment;

                    if (newStartTime.isAfter(endTime.subtract(60, 'minutes'))) {
                      message.warn('End time was changed to 1 hour later according to start time');
                      form.setFieldsValue({
                        end_time: newStartTime.clone().add(60, 'minutes'),
                      });
                    }

                    return newStartTime;
                  },
                }}
                input={{
                  format: 'MMM D, YYYY [at] h:mm A',
                  showTime: { format: 'h:mm A' },
                }}
              />
            </Col>
          ) : null}
          <Col item={{ span: 12 }}>
            <DateTimeInput
              item={{
                name: 'end_time',
                label: 'Ends on',
                rules: [
                  {
                    required: true,
                    message: t('Please provide end date'),
                  },
                  () => ({
                    // eslint-disable-next-line @typescript-eslint/promise-function-async
                    validator(_, value) {
                      if (isProgram && value.isAfter(program?.end_time)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Poll end time must be set before Program end time');
                      }

                      return Promise.resolve();
                    },
                  }),
                ],
                getValueFromEvent(newEndTime: Moment) {
                  const startTime = form.getFieldValue('start_time') as Moment;

                  if (newEndTime.isBefore(startTime) || newEndTime.diff(startTime, 'minutes') < 60) {
                    message.warn('End time was changed to 1 hour later according to start time');
                    return startTime.clone().add(60, 'minutes');
                  }

                  return newEndTime;
                },
              }}
              input={{
                format: 'MMM D, YYYY [at] h:mm A',
                showTime: { format: 'h:mm A' },
              }}
            />
          </Col>
        </Row>
        <TextInput
          item={{
            name: 'title',
            label: 'Title',
            rules: [{ required: true }],
          }}
        />
      </Section>
      {(!poll.id || program?.status === 'draft') && (
        <Section paddingBottom={false}>
          <Title level={3}>Poll content</Title>
          <PollQuestions name="questions" defaultQuestion={defaultQuestion()} />
        </Section>
      )}
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {edit || isProgram ? (
        <>
          <Section paddingBottom={false}>
            <Title level={3}>Attachments</Title>
            <AttachmentsInput item={{ name: 'attachments' }} />
          </Section>
          <Section paddingBottom={false}>
            <Title level={3}>Additional details</Title>
            <RichTextInput
              text={{ name: 'description' }}
              html={{ name: 'html_description', label: t('Description (Optional)'), maxWidth: '100%' }}
            />
          </Section>
        </>
      ) : null}
      <ModalFormActions submit={{ children: edit ? 'Edit Poll' : 'Create Poll', id: 'submit-poll' }} />
    </Form>
  );
};

export default PollForm;
/* eslint-enable @typescript-eslint/naming-convention -- End: We missing correct application model in camelCase, we need to fix it */
