import {
  type Interest,
  type EducationLevel,
  type Gender,
  type Profession,
  type Image,
  type ReduxUser,
  type DefaultImage,
} from 'models';
import { useTranslation } from 'react-i18next';
import { uploadImage } from 'common/queries';
import { timezones } from 'common/utils';
import {
  Col,
  Form,
  PhoneInput,
  Row,
  SelectInput,
  TextInput,
  TextAreaInput,
  BirthDateInput,
  Button,
  type ImageUploadedData,
  type BirthDateType,
  ImageInput,
  getBirthDateTypeValue,
  transformImageForInit,
  Title,
} from 'ui';
import { AdressFieldset } from './AdressFieldset';
import { NameFieldset } from './NameFieldset';

export type ProfileFormValues = {
  profilePhoto?: ImageUploadedData<DefaultImage | Image>;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate?: BirthDateType;
  timeZone: string;
  gender?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  street?: string;
  apt?: string;
  educationLevel?: string;
  company?: string;
  profession?: string;
  biography?: string;
  interests?: string[];
};

const getInitialValues = (user: ReduxUser): Partial<ProfileFormValues> => ({
  firstName: user.first_name,
  middleName: user.middle_name,
  lastName: user.last_name,
  email: user.email,
  phone: user.phone,
  timeZone: user.time_zone,
  birthDate: getBirthDateTypeValue(user.birthdate),
  profilePhoto: user.profile_photo ? transformImageForInit(user.profile_photo) : undefined,
  educationLevel: user.education_level?.id,
  gender: user.gender?.id,
  profession: user.profession?.id,
  interests: user.interests?.map((interest) => interest.id),
  company: user.company,
  biography: user.biography,
  country: user.country,
  state: user.state,
  city: user.city,
  zip: user.zip,
  street: user.street,
  apt: user.apt,
});

type P = {
  user: ReduxUser;
  onSubmit: (values: ProfileFormValues) => void;
  genders: Gender[];
  professions: Profession[];
  educationLevels: EducationLevel[];
  interests: Interest[];
  loading: boolean;
};

const ProfileForm = ({ user, onSubmit, genders, professions, educationLevels, interests, loading }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ProfileFormValues>();

  const onFinish = (data: ProfileFormValues) => {
    onSubmit(data);
  };

  return (
    <Form<ProfileFormValues> onFinish={onFinish} form={form} initialValues={getInitialValues(user)}>
      <Title level={4} marginTop={false}>
        About me
      </Title>
      <Row>
        <Col item={{ span: 24 }}>
          <ImageInput
            uploadFile={uploadImage}
            item={{ name: 'profilePhoto', label: 'Avatar' }}
            type="round"
            size="large"
          />
        </Col>

        <Col item={{ span: 24 }}>
          <NameFieldset<ProfileFormValues> />
        </Col>
      </Row>

      <Row>
        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'email',
              label: 'E-mail',
              rules: [
                { type: 'email' },
                {
                  required: true,
                },
              ],
            }}
            input={{
              autocomplete: 'email',
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <PhoneInput
            item={{
              name: 'phone',
              label: 'Phone number',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              autocomplete: 'tel',
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <BirthDateInput
            item={{
              name: 'birthDate',
              label: 'Date of birth',
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'timeZone',
              label: 'My timezone',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              showSearch: true,
              options: timezones,
              getOptionLabel: (option) => option.label,
              getOptionValue: (option) => option.value,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'gender',
              label: 'Gender',
            }}
            input={{
              showSearch: true,
              options: genders,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            }}
          />
        </Col>

        <Title level={4}>Address</Title>

        <Col item={{ span: 24 }}>
          <AdressFieldset<ProfileFormValues> />
        </Col>

        <Title level={4}>Other details</Title>

        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'educationLevel',
              label: 'Education level',
            }}
            input={{
              showSearch: true,
              options: educationLevels,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'profession',
              label: 'Profession',
            }}
            input={{
              showSearch: true,
              options: professions,
              getOptionLabel: (option) => t(option.name),
              getOptionValue: (option) => option.id,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'company',
              label: 'Company name/school',
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <TextAreaInput
            item={{
              name: 'biography',
              label: 'Biography',
            }}
            input={{
              rows: 1,
              style: { lineHeight: '30px' },
              autoSize: true,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'interests',
              label: 'Interests',
              // TODO after update on ant design 5 in #57416 has Select input new propery maxCount
              rules: [
                {
                  async validator(_, value) {
                    if (value.length > 3) {
                      throw 'Error: You can choose only 3 interests';
                    }
                  },
                },
              ],
              extra: 'You can choose multiple options',
            }}
            input={{
              options: interests,
              mode: 'multiple',
              showSearch: true,
              getOptionLabel: (option) => t(option.name),
              getOptionValue: (option) => option.id,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <Button type="primary" htmlType="submit" loading={loading} size="large">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfileForm;
