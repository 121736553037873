import classNames from 'classnames';

type SvgWrapperProperties = React.SVGProps<SVGSVGElement>;

const withAntIconWrapper = (SvgComponent: React.FunctionComponent<SvgWrapperProperties>) => {
  return ({ type, ...props }: SvgWrapperProperties & { type?: 'error' | 'success' }) => (
    <span
      className={classNames('appicon', 'anticon', {
        'appicon--success': type === 'success',
        'appicon--error': type === 'error',
      })}
    >
      <SvgComponent {...props} />
    </span>
  );
};

export default withAntIconWrapper;
