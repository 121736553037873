import { Component } from 'react';
import { parseISO } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { CommentsList } from '../comment';
import { can } from '../../utils/permissions';
import { AutocompleteService } from '../../services/AutocompleteService';
import PollRendererCore from '../../../features/polls/PollRendererCore';
import { ReadMore, EditIcon, ReportIcon, DeleteIcon, BookmarkIconLine } from 'ui';
import CommentWrapper from './shared/CommentWrapper';
import { canEditContentObject, canDeleteContentObject, canReportContentObject } from 'permissions';
import { ContentObjectHeader, ContentObjectFooter, CommentsEmpty, CreateCommentForm } from 'features/feed';
import { images } from 'common/utils';

export class Renderer extends Component {
  onRequestMentions = async (value) => {
    if (this.props.item.post_in_communities?.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.item.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    if (this.props.item.program?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.item.program.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  createActions = () => {
    const { t, isBookmark, viewer, item, onBookmarkObject, onReportObject, onInitiatePostEditing, onDeleteObject } =
      this.props;

    if (isBookmark) {
      return [
        {
          label: t('Unbookmark'),
          key: 'unbookmark',
          onClick: () => onBookmarkObject(item),
          icon: <BookmarkIconLine width={16} height={16} />,
        },
      ];
    }

    const actions = [];

    if (canReportContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        onClick: () => onReportObject(item),
        icon: <ReportIcon width={16} height={16} />,
      });
    }

    if (canEditContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Edit'),
        key: 'update',
        onClick: () => onInitiatePostEditing(item),
        icon: <EditIcon width={16} height={16} />,
      });
    }

    if (canDeleteContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Delete'),
        key: 'delete',
        onClick: () => onDeleteObject({ object: item }),
        icon: <DeleteIcon width={16} height={16} />,
        danger: true,
      });
    }

    return actions;
  };

  render() {
    const { item, isBookmark, isOnFeed } = this.props;
    const showComments = this.props.showComments;

    const pollBody = (
      <PollRendererCore
        poll={{ ...item, ...item.poll, attachments: item.attachments }}
        isDisabled={can(item.program, 'Course', 'contentCreator') || item.author.id === this.props.viewer.id}
        isBookmark={isBookmark}
        isOnFeed={this.props.isOnFeed}
        showChoicePercentage={
          can(item.program, 'Course', 'contentCreator') ||
          can(item.post_in_communities, 'ContentObject', 'viewPollChoicePercentage')
        }
      />
    );

    return (
      <>
        <ContentObjectHeader
          title={this.props.authorName}
          subtitle={parseISO(this.props.item.publish_at)}
          avatar={{
            url: this.props.item.author.profile_photo?.url ?? images.default.userProfilePhoto,
            alias: this.props.authorName,
          }}
          bookmark={this.props.bookmark}
          actions={this.props.hideActions ? undefined : this.createActions()}
          pill={this.props.pill}
          authorId={this.props.item.author.id}
        />
        {isOnFeed ? <ReadMore>{pollBody}</ReadMore> : pollBody}
        {!this.props.isRepost ? (
          <ContentObjectFooter
            item={item}
            viewer={this.props.viewer}
            onShowComments={this.props.onShowComments}
            view={this.props.isBookmark ? 'bookmark' : 'other'}
          />
        ) : null}
        {!this.props.isBookmark && showComments && (
          <CommentWrapper>
            {item.replies.length > 0 ? (
              <div style={{ padding: '10px 0' }}>
                <CommentsList
                  item={item}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                  onCreateComment={this.props.onCreateComment}
                  onDeleteObject={this.props.onDeleteObject}
                  onEditObject={this.props.onEditComment || this.props.onEditObject}
                  onLikeObject={this.props.onLikeObject}
                  onReportObject={this.props.onReportObject}
                  onSortChange={this.props.onSortChange}
                  onBookmarkObject={this.props.onBookmarkObject}
                  parent="post"
                  viewer={this.props.viewer}
                  originalItem={item}
                  course={item.program}
                />
              </div>
            ) : (
              <CommentsEmpty />
            )}

            {can(item, 'ContentObject', 'comment') ? (
              <div style={{ padding: '16px' }}>
                <CreateCommentForm
                  viewer={this.props.viewer}
                  parentId={item.id}
                  onRequestMentions={this.onRequestMentions}
                />
              </div>
            ) : null}
          </CommentWrapper>
        )}
      </>
    );
  }
}

export const PollRenderer = withTranslation()(Renderer);
