import { type ImageDataObject } from 'models/service';
import { type ApiClientResponse } from '.';
import { apiClient, routes } from '.';

export const uploadFile = async (
  file: Blob | File | string,
  onProgress?: (event: { target: XMLHttpRequest; loaded: number; total: number }) => void,
) => {
  const form = new FormData();
  form.append('file', file);

  return apiClient.post<ImageDataObject, ApiClientResponse<ImageDataObject>>(routes.files.upload, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onProgress,
  });
};
