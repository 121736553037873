import { HlsVideoPlayer, Row, Col, Title } from 'ui';

const PageVideo = () => {
  return (
    <Row>
      <Col item={{ span: 24 }}>
        <Title level={2}>Hls video player - (demo version)</Title>
      </Col>
      <Col item={{ span: 12 }}>
        <HlsVideoPlayer
          video={{
            src: 'https://content.jwplatform.com/manifests/yp34SRmf.m3u8',
          }}
        />
      </Col>
    </Row>
  );
};

export default PageVideo;
