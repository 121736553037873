import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InfinityScrollSelect, Modal, ModalFormActions, Space, Text, RichTextEditor } from 'ui';
import { useChangelogData } from './hooks';

type VersionOption = {
  label: string;
  value: string;
  changelog: {
    version: string;
    htmlContent: string;
  };
};

type ChangelogFormValues = {
  version?: VersionOption;
};

const ChangelogModal = ({ isOpened, onClose }: { isOpened: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { data, updateLatestWhatIsNewVersion } = useChangelogData();

  const changelogOptions = useMemo(
    () =>
      data.map((versionData) => ({
        label:
          versionData.version === String(process.env.REACT_APP_VERSION)
            ? `${versionData.version} - Current version`
            : versionData.version,
        value: versionData.version,
        changelog: versionData,
      })),
    [data],
  );

  const initialValues: ChangelogFormValues = {
    version: changelogOptions[0],
  };

  const handleCancel = () => {
    updateLatestWhatIsNewVersion();
    onClose();
  };

  return (
    <Modal
      title="Release notes"
      isOpened={isOpened}
      maskClosable={false}
      width="large"
      disableBack
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form<ChangelogFormValues> initialValues={initialValues}>
        <Space direction="vertical" size="middle">
          <Text>
            {t(
              "Here's What's new! The latest release includes new features, improvements, and bug fixes to make your experience better than ever",
            )}
          </Text>
          <Form.Item name="version" label="Select version" rules={[{ required: true }]}>
            <InfinityScrollSelect<VersionOption>
              placeholder={t('Select version')}
              loading={false}
              options={changelogOptions}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              handleRequestMoreData={() => {}}
              hasMoreData={false}
            />
          </Form.Item>
          <Form.Item noStyle dependencies={['version']}>
            {({ getFieldsValue }) => {
              const { version } = getFieldsValue(true) as ChangelogFormValues;

              const htmlContent = version?.changelog.htmlContent;

              return <RichTextEditor editable={false} data={htmlContent} />;
            }}
          </Form.Item>
        </Space>
        <ModalFormActions submit={{ children: 'Got it', onClick: handleCancel }} />
      </Form>
    </Modal>
  );
};

export default ChangelogModal;
