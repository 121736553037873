import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { CommentsList } from '../comment';
import { can } from '../../utils/permissions';
import { AutocompleteService } from '../../services/AutocompleteService';
import { Text } from '../text';
import ActivityTask from '../../../features/programs/feed/ActivityTask';
import { ReadMore, ReportIcon, BookmarkIconLine } from 'ui';
import CommentWrapper from './shared/CommentWrapper';
import { ContentObjectHeader, ContentObjectFooter, CommentsEmpty, CreateCommentForm } from 'features/feed';
import { parseISO } from 'date-fns';

import { canReportContentObject } from 'permissions';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  onRequestMentions = async (value) => {
    if (this.props.item.post_in_communities?.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.item.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    if (this.props.item.program?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.item.program.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  createActions = () => {
    const { t, isBookmark, viewer, item, onBookmarkObject, onReportObject } = this.props;

    if (isBookmark) {
      return [
        {
          label: t('Unbookmark'),
          key: 'unbookmark',
          onClick: () => onBookmarkObject(item),
          icon: <BookmarkIconLine width={16} height={16} />,
        },
      ];
    }

    const actions = [];

    if (canReportContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        onClick: () => onReportObject(item),
        icon: <ReportIcon width={16} height={16} />,
      });
    }

    return actions;
  };

  render() {
    const { item, isOnFeed } = this.props;
    const showComments = this.props.showComments;

    const activityBody = (
      <ActivityTask
        activity={item.activity}
        onSuccess={() => this.props.onReloadObject(item)}
        canDownloadReport={can(item.program, 'Course', 'downloadReport')}
        canSubmitData={can(item.activity, 'Activity', 'submitData')}
        reloadingPost={this.props.reloadingPost}
        isProgramPublished={item.program.status === 'published'}
        userSubscribed={can(item.program, 'Course', 'joinedAsUser')}
        isBookmark={this.props.isBookmark}
        isOnFeed={isOnFeed}
        dexcomSynced={this.props.viewer.dexcom.synced}
      />
    );

    return (
      <>
        <ContentObjectHeader
          title={this.props.authorName}
          subtitle={parseISO(this.props.item.publish_at)}
          avatar={{
            url: this.props.item.author.profile_photo?.url ?? images.default.userProfilePhoto,
            alias: this.props.authorName,
          }}
          bookmark={this.props.bookmark}
          actions={this.createActions()}
          pill={this.props.pill}
          authorId={this.props.item.author.id}
        />
        <Text component="div" size={16} weight="600" style={{ padding: '0 24px 16px' }}>
          {item.activity.name}
        </Text>
        {isOnFeed ? <ReadMore>{activityBody}</ReadMore> : activityBody}
        <ContentObjectFooter
          item={item}
          viewer={this.props.viewer}
          onShowComments={this.props.onShowComments}
          view={this.props.isBookmark ? 'bookmark' : 'other'}
        />
        {!this.props.isBookmark && showComments && (
          <CommentWrapper>
            {item.replies.length > 0 ? (
              <div style={{ padding: '10px 0' }}>
                <CommentsList
                  item={item}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                  onCreateComment={this.props.onCreateComment}
                  onDeleteObject={this.props.onDeleteObject}
                  onEditObject={this.props.onEditComment || this.props.onEditObject}
                  onLikeObject={this.props.onLikeObject}
                  onReportObject={this.props.onReportObject}
                  onSortChange={this.props.onSortChange}
                  onBookmarkObject={this.props.onBookmarkObject}
                  parent="post"
                  viewer={this.props.viewer}
                  originalItem={item}
                  course={item.program}
                />
              </div>
            ) : <CommentsEmpty />}

            {can(item, 'ContentObject', 'comment') ? (
              <div style={{ padding: '16px' }}>
                <CreateCommentForm
                  viewer={this.props.viewer}
                  parentId={item.id}
                  onRequestMentions={this.onRequestMentions}
                />
              </div>
            ) : null}
          </CommentWrapper>
        )}
      </>
    );
  }
}

export const ActivityRenderer = withTranslation()(Renderer);
