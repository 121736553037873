// NOTE: Do not change import order! Some imports are dependent on the others and changing the order may cause problems
/* eslint-disable import/order */
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query';
// Store and persistor
import { store, persistor } from './old/store';
// Router
import { MainRouter } from './old/routers';
// Other
import { AppConfigGate } from './old/atoms/app-config-gate';
import { ThemeProvider } from './old/atoms/theme';
import { queryClient } from 'query';
import './i18n';
import { RecoilRoot } from 'recoil';
import { ConnectionSocket } from './common/services';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { RichTextEditorProvider, richTextEditorSettings } from 'lib/react-rich-text-editor';

export function App() {
  return (
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}>
      <RecoilRoot>
        <Provider store={store}>
          <PersistGate loading="Loading" persistor={persistor}>
            <AppConfigGate>
              <ThemeProvider>
                <RichTextEditorProvider value={richTextEditorSettings}>
                  <QueryClientProvider client={queryClient}>
                    <ConnectionSocket />
                    <MainRouter />
                  </QueryClientProvider>
                </RichTextEditorProvider>
              </ThemeProvider>
            </AppConfigGate>
          </PersistGate>
        </Provider>
      </RecoilRoot>
    </GoogleOAuthProvider>
  );
}
/* eslint-enable import/order */
