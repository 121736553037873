import { ExclamationCircleOutlined } from '@ant-design/icons';
import { type ItemType } from 'antd/lib/menu/hooks/useItems';
import { type Reply, type ContentObjectRedux } from 'models';
import { useTranslation } from 'react-i18next';
import { copyLinkToClipBoard } from 'common/utils';
import { type ReplyDataObject } from 'models/service';
import { type ReduxUser } from 'models/user';
import { canDeleteComment, canEditComment, canReportContentObject } from 'permissions';
import {
  Dropdown,
  Button,
  DotsIcon,
  BookmarkIconFill,
  BookmarkIconLine,
  message,
  LinkLine,
  EditIcon,
  ReportIcon,
  DeleteIcon,
  modalConfirm,
} from 'ui';
import {
  useBookmarkObjectMutation,
  useReportObjectMutation,
  useUnbookmarkObjectMutation,
  useDeleteCommentMutation,
} from '../../../queries';

type P = {
  viewer: ReduxUser;
  comment: Reply;
  commentDataObject: ReplyDataObject;
  contentObjectRedux: ContentObjectRedux;
  onEditObject: () => void;
};

const CommentActions = ({ viewer, comment, commentDataObject, contentObjectRedux, onEditObject }: P) => {
  const { t } = useTranslation();

  const { mutate: bookmarkComment, isLoading: isBookmarking } = useBookmarkObjectMutation();
  const { mutate: unbookmarkComment, isLoading: isUnbookmarking } = useUnbookmarkObjectMutation();
  const { mutateAsync: reportComment } = useReportObjectMutation();
  const { mutate: deleteComment, isLoading: isDeleting } = useDeleteCommentMutation();

  const handleBookmark = (comment: Reply) => {
    comment.bookmarked
      ? unbookmarkComment({ item: comment, reduxItem: commentDataObject })
      : bookmarkComment({ item: comment, reduxItem: commentDataObject });
  };

  const copyLink = async () => {
    try {
      // TODO: make new ts version
      await copyLinkToClipBoard(commentDataObject);
      message.success('Link copied to clipboard');
    } catch {
      message.error('Sorry, something went wrong.');
    }
  };

  const handleReport = async () => {
    modalConfirm({
      title: t('Report this Post/Event/Comment?'),
      cancelText: t('Cancel'),
      okText: t('Report'),
      async onOk() {
        return reportComment(
          { item: comment },
          {
            onSuccess() {
              message.success(t('Successfully reported.'));
            },
            onError() {
              message.error(t('Something went wrong.'));
            },
          },
        );
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const onSelectKey = ({ key }: { key: string }) => {
    switch (key) {
      case 'link':
        copyLink();
        break;
      case 'edit':
        onEditObject();
        break;
      case 'report':
        handleReport();
        break;
      case 'delete':
        deleteComment({ item: comment, reduxItem: commentDataObject });
        break;
    }
  };

  const menu = () => {
    const actions: ItemType[] = [
      {
        label: t('Copy link'),
        key: 'link',
        icon: <LinkLine width={16} height={16} />, // TODO some size prop should handle icon size
      },
    ];

    if (canEditComment({ viewer, comment })) {
      actions.push({
        label: t('Edit'),
        key: 'edit',
        icon: <EditIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    if (canReportContentObject({ viewer, contentObject: comment })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        icon: <ReportIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    if (canDeleteComment({ viewer, comment, contentObject: contentObjectRedux })) {
      actions.push({
        label: t('Delete'),
        key: 'delete',
        danger: true,
        icon: <DeleteIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    return actions;
  };

  return (
    <>
      <Button
        // TODO in loading this button dont look as it should
        loading={isBookmarking || isUnbookmarking}
        icon={comment.bookmarked ? <BookmarkIconFill /> : <BookmarkIconLine />}
        type="text"
        onClick={() => {
          handleBookmark(comment);
        }}
      />
      <Dropdown
        menu={{
          items: menu(),
          onClick(key) {
            onSelectKey(key);
          },
        }}
        trigger={['click']}
      >
        <Button icon={<DotsIcon />} loading={isDeleting} type="link-secondary" />
      </Dropdown>
    </>
  );
};

export default CommentActions;
